import { getFeature } from '~/techstyle-shared/react-features';

import featureNames from '../constants/featureFlags';

import truthyQueryParameter from './truthyQueryParameter';

export const isVerifiedBot = (ctx) => {
  const { isEnabled: testingAsBot } = getFeature(
    ctx.store.getState(),
    featureNames.BOT_TEST
  );

  const isVerifiedBot = ctx.isVerifiedBot || ctx.req?.get('cf-verified-bot');
  ctx.isVerifiedBot = isVerifiedBot;
  return testingAsBot || truthyQueryParameter(isVerifiedBot);
};
