import getServerSideRetailPriceFeature from '~/deprecated/utils/getServerSideRetailPriceFeature';
import featureNames from '~/shared/constants/featureFlags';
import { getCategoryNav } from '~/shared/utils/getCategoryNav';
import { getCategoryNavContainer } from '~/shared/utils/getCategoryNavContainer';
import { getCategoryPath } from '~/shared/utils/getCategoryPath';
import getHasFeature from '~/shared/utils/getHasFeature';
import { getGender } from '~/shared/utils/useGender';
import { getMembership } from '~/techstyle-shared/react-accounts';
import { loadFeatures, getFeature } from '~/techstyle-shared/react-features';
import { loadNav } from '~/techstyle-shared/react-navigation';

import { getBuilderCollectionContent } from './getBuilderCollectionContent';

export const getGridNodeArrayFromCollectionsPageBuilderData = async ({
  ctx,
  builderData,
}) => {
  if (!builderData) {
    return [];
  }
  const { query } = ctx;
  const productRequestParams = [];
  const rewriteFeatureData = {};
  const membership = getMembership(ctx.store.getState());
  const customer = ctx.store.getState().customer;
  const tld = ctx.store.getState().domain.tld;

  const genderAutoFilterEnabled = getHasFeature(
    ctx,
    featureNames.GENDER_AUTO_FILTER
  );

  const { gender: userGender = null, genderFilterString } = getGender(
    ctx.store.getState()
  );

  const displayRetailPrice = await getServerSideRetailPriceFeature({ ctx });

  const {
    intl: { region },
  } = ctx.store.getState();

  const rewriteSort = rewriteFeatureData?.sort;

  let gridSortFeatureData;
  if (builderData?.gridSortFeature?.length) {
    await ctx.store.dispatch(loadFeatures([builderData.gridSortFeature]));

    const { data } = getFeature(
      ctx.store.getState(),
      builderData.gridSortFeature
    );
    gridSortFeatureData = data;
  }

  const pathname = ctx.asPath?.split('?') ? ctx.asPath?.split('?')[0] : null;
  const routeInfo = {
    canonicalPath: pathname,
  };

  const categoryPath = routeInfo ? getCategoryPath(routeInfo) : {};
  let categoryNav = {};
  if (routeInfo || builderData.categoryNavContainer) {
    const categoryNavContainer =
      builderData.categoryNavContainer ||
      getCategoryNavContainer(region, categoryPath);

    if (categoryNavContainer && categoryPath) {
      const navRequest = ctx.store.dispatch(loadNav(categoryNavContainer));
      await navRequest;

      categoryNav = getCategoryNav(
        ctx.store.getState(),
        categoryPath,
        categoryNavContainer
      );
    }
  }
  const index = productRequestParams.length;
  const { productSource } = builderData;

  const productGridItemParams = {
    query,
    membership,
    customer,
    gender: userGender,
    genderFilterString,
    region,
    tld,
    categoryNav,
    rewriteSort,
    displayRetailPrice,
    genderAutoFilterEnabled,
    gridSortFeatureData,
    productSource,
    options: builderData,
    index,
    dispatch: ctx.store.dispatch,
    routeInfo,
  };

  const collectionContent = await getBuilderCollectionContent(
    productGridItemParams
  );
  return collectionContent;
};
