import { getGridNodeArrayFromCollectionsPageBuilderData } from './getGridNodeArrayFromCollectionsPageBuilderData';

export const loadAsyncBuilderComponents = async ({ ctx, builderJson }) => {
  // process the default block as well as the variations
  const blocksToProcess = [
    { url: builderJson?.data.url, blocks: builderJson?.data.blocks },
    ...(builderJson?.variations
      ? Object.keys(builderJson.variations).map((key) => {
          return {
            url: builderJson?.data.url,
            blocks: builderJson?.variations[key].data.blocks,
          };
        })
      : []),
  ];

  // multi-thread the processing
  const processedBuilderBlocks = await Promise.all(
    blocksToProcess.map(({ url, blocks }) =>
      processBuilderBlocks({ ctx, url, blocks })
    )
  );

  let collections = processedBuilderBlocks.flatMap(
    ({ collections }) => collections
  );
  let gridLists = processedBuilderBlocks.flatMap(({ gridLists }) => gridLists);

  collections = collections?.filter((item) => !!item);
  gridLists = gridLists?.filter((item) => !!item);

  return { collections, gridLists };
};

const processBuilderBlocks = async ({ ctx, blocks, url }) => {
  /**
   * Do not attempt to move these methods into builderPageExtension.js or else it will break
   * the app due to circular references found inside of processNodes() in node.js.
   *
   * This logic is consumed inside of BuilderCollection.js but cannot be relocated to that file
   * because processNodes() cannot be executed on the client-side.
   */
  const collections = blocks
    ? await Promise.all(
        blocks
          .filter((block) => block?.component?.name === '_Collection_')
          .map(async ({ component, id }) => {
            const content =
              await getGridNodeArrayFromCollectionsPageBuilderData({
                ctx,
                builderData: {
                  url: url,
                  ...component.options,
                },
              });
            return {
              id,
              content,
            };
          })
      )
    : null;

  /**
   * This logic is consumed inside of BuilderGridList.js but cannot be relocated to that file
   * because processNodes() cannot be executed on the client-side.
   */
  const gridLists = blocks
    ? await Promise.all(
        blocks
          .filter((block) => block?.component?.name === '_GridList_')
          .map(async ({ component, id }) => {
            const content = await Promise.all(
              component.options.collections?.map(async (collection, i) => {
                return await getGridNodeArrayFromCollectionsPageBuilderData({
                  ctx,
                  builderData: {
                    url: url,
                    filters: i === 0 ? component.options.filters : null,
                    ...collection,
                  },
                });
              })
            );
            return {
              id,
              content,
            };
          })
      )
    : null;

  return { collections, gridLists };
};
