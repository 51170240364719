import { getSectionsAndQueryFromPath } from './routing';

function translateNavToCategoryHierarchy({ navItem, isTopLevel = false }) {
  const { isResourceBundle, label, navItems, url } = navItem;

  const urlPath = url;

  const subcategories =
    navItems && navItems.length
      ? navItems.map((item) =>
          translateNavToCategoryHierarchy({
            navItem: item,
          })
        )
      : [];

  return {
    ancestors: [],
    siblings: [],
    isTopLevel,
    label,
    isResourceBundle,
    subcategories,
    urlPath,
  };
}

export function getCategories({ navItems, asPath }) {
  const {
    root: asPathRootSection,
    section: asPathSection,
    subsection: asPathSubSection,
    subsubsection: asPathSubSubSection,
  } = getSectionsAndQueryFromPath(asPath);

  const defaultActiveCategory = {
    ancestors: [],
    isTopLevel: false,
    label: asPathRootSection,
    siblings: [],
    subcategories: [],
    url: asPath,
  };

  let activeCategory = defaultActiveCategory;
  const breadcrumbs = [];
  let navLevelRoot;
  let navLevelOne;
  let navLevelTwo;
  let navLevelThree;

  // organize navLevelRoot
  if (asPathRootSection) {
    navLevelRoot = navItems.find((item) => {
      const { root } = getSectionsAndQueryFromPath(item.url);
      return root === asPathRootSection;
    });
  }

  // if we didn't find the route in navigationizer abandon this
  if (!navLevelRoot) {
    return {
      activeCategory: null,
      breadcrumbs: [],
    };
  }

  if (navLevelRoot) {
    const breadcrumbsLevelRoot = translateNavToCategoryHierarchy({
      navItem: navLevelRoot,
      isTopLevel: false,
    });

    breadcrumbs.push(breadcrumbsLevelRoot);
  }

  // root only
  if (breadcrumbs[0] && !asPathSection) {
    activeCategory = breadcrumbs[0];
    activeCategory.isTopLevel = true;

    return {
      activeCategory,
      breadcrumbs,
    };
  }

  // organize navLevelOne
  if (asPathSection) {
    navLevelOne = asPathSection
      ? navLevelRoot.navItems.find((item) => {
          const { section } = getSectionsAndQueryFromPath(item.url);
          return section === asPathSection;
        })
      : null;
  }

  const breadcrumbsLevelOne = navLevelOne
    ? translateNavToCategoryHierarchy({
        navItem: navLevelOne,
        isTopLevel: true,
      })
    : null;

  // breakout if no subcategories exist past level one
  if (!navLevelOne || !navLevelOne.navItems) {
    activeCategory = breadcrumbsLevelOne;
    if (breadcrumbsLevelOne) {
      breadcrumbs.push(breadcrumbsLevelOne);
    }

    return {
      activeCategory,
      breadcrumbs,
    };
  }

  if (breadcrumbsLevelOne) {
    activeCategory = breadcrumbsLevelOne;
    breadcrumbsLevelOne.ancestors = [];
    breadcrumbsLevelOne.siblings = navLevelRoot.navItems.map((siblingItem) =>
      translateNavToCategoryHierarchy({
        navItem: siblingItem,
        isTopLevel: false,
      })
    );

    breadcrumbs.push(breadcrumbsLevelOne);
  }

  // organize navLevelTwo
  if (asPathSubSection) {
    navLevelTwo = asPathSubSection
      ? navLevelOne.navItems.find((item) => {
          const { subsection } = getSectionsAndQueryFromPath(item.url);
          return subsection === asPathSubSection;
        })
      : null;
  }

  const breadcrumbsLevelTwo = navLevelTwo
    ? translateNavToCategoryHierarchy({
        navItem: navLevelTwo,
        isTopLevel: false,
      })
    : null;

  if (breadcrumbsLevelTwo) {
    activeCategory = breadcrumbsLevelTwo;
    breadcrumbsLevelTwo.ancestors = [breadcrumbsLevelOne];
    breadcrumbsLevelTwo.siblings = navLevelOne.navItems.map((siblingItem) =>
      translateNavToCategoryHierarchy({
        navItem: siblingItem,
        isTopLevel: false,
      })
    );

    breadcrumbs.push(breadcrumbsLevelTwo);
  }

  // organize navLevelThree
  if (asPathSubSubSection) {
    navLevelThree = asPathSubSubSection
      ? navLevelTwo.navItems.find((item) => {
          const { subsubsection } = getSectionsAndQueryFromPath(item.url);
          return subsubsection === asPathSubSubSection;
        })
      : null;
  }

  const breadcrumbsLevelThree = navLevelThree
    ? translateNavToCategoryHierarchy({
        navItem: navLevelThree,
        isTopLevel: false,
      })
    : null;

  if (breadcrumbsLevelThree) {
    activeCategory = breadcrumbsLevelThree;
    breadcrumbsLevelThree.ancestors = [breadcrumbsLevelTwo];
    breadcrumbsLevelThree.siblings = navLevelTwo.navItems.map((siblingItem) =>
      translateNavToCategoryHierarchy({
        navItem: siblingItem,
        isTopLevel: false,
      })
    );

    breadcrumbs.push(breadcrumbsLevelThree);
  }

  return {
    activeCategory,
    breadcrumbs,
  };
}
