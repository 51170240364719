import { loadDirectMarketingDisposition } from '~/techstyle-shared/react-marketing';

export async function getExperienceId({
  isLoggedIn,
  dispatch,
  disposition,
  dmg,
  gender,
}) {
  let experienceId;
  let code;
  const options = {};

  // allow a dmg parameter to overwrite your dmg
  // this is in place for CF landing page navigation
  if (!isLoggedIn && dmg) {
    code = dmg;
  }

  // allow a gender parameter to overwrite your gender
  if (!isLoggedIn && gender) {
    options.gender = gender;
  }

  // only load it if we don't already have the disposition or if we are supposed to change the dmg or gender
  if (
    disposition?.experience &&
    (!code || code === disposition?.gateway) &&
    (!options?.gender || disposition?.gender === options?.gender)
  ) {
    return disposition.experience;
  }

  try {
    disposition = await dispatch(loadDirectMarketingDisposition(code, options));
    experienceId = disposition?.payload.experience;
  } catch (e) {
    throw new Error('No experience identifier retrieved');
  }

  if (!experienceId || !experienceId.length) {
    throw new Error('No experience identifier found');
  }
  return experienceId;
}
