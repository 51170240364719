// this file is intended for moving feature flag definitions to
// when feature flags become fairly static. The side effect of
// leaving feature flags in feature creature after the feature
// is finalized is that we have unintended side effects and
// re-renders due to retrieval of feature flags client side
// re-triggering hooks.

import { getMembership } from '~/techstyle-shared/react-accounts';
import { getFeature, loadFeatures } from '~/techstyle-shared/react-features';
import { getSession } from '~/techstyle-shared/redux-core';

import { staticFeatureValidators } from '../constants/featureFlags';

import { getGender } from './useGender';

export default async function getHasFeature(ctx, handle) {
  const session = getSession(ctx.store.getState());
  const membership = getMembership(ctx.store.getState());
  const tld = ctx.store.getState().domain.tld;
  const { gender } = getGender(ctx.store.getState());
  const hasStaticFeature = staticFeatureValidators[handle];

  if (hasStaticFeature) {
    return staticFeatureValidators[handle]({
      session,
      membership,
      gender,
      tld,
    });
  }

  // make a call to getFeature to get from feature creature

  await ctx.store.dispatch(loadFeatures(handle));
  const { isEnabled } = getFeature(ctx.store.getState(), handle);
  return isEnabled;
}
