import { getExperienceId } from '~/acquisition/utils/customizedExperience';
import { getProfileSizeFilters } from '~/shared/utils/getProfileSizeFilters';
import { isVerifiedBot } from '~/shared/utils/isVerifiedBot';
import { getGender } from '~/shared/utils/useGender';
import {
  getLoyaltyDetails,
  getMembership,
  loadMembership,
  loadLoyaltyDetails,
} from '~/techstyle-shared/react-accounts';
import { getDirectMarketingDisposition } from '~/techstyle-shared/react-marketing';
import { getSession } from '~/techstyle-shared/redux-core';

import { getBuilderJson } from './getBuilderJson';
import { getBuilderMembershipStatus } from './getBuilderMembershipStatus';
import { getBuilderPageTargeting } from './getBuilderPageTargeting';
import { isBuilderPreview } from './isBuilderPreview';
import { loadAsyncBuilderComponents } from './loadAsyncBuilderComponents';
import { ssrPreprocessBuilderJson } from './ssrPreprocessBuilderJson';

export const getBuilderJsonSSR = async ({
  modelName,
  urlPath,
  ctx,
  query = {},
  ignoreExperienceIdTargeting = false,
}) => {
  await Promise.all([
    ctx.store.dispatch(loadMembership()),
    ctx.store.dispatch(loadLoyaltyDetails()),
  ]);

  const membership = getMembership(ctx.store.getState());
  const isPreview = isBuilderPreview(ctx.req);
  const state = ctx.store.getState();
  const { isLoggedIn } = getSession(state);
  const membershipStatus = getBuilderMembershipStatus(membership, isLoggedIn);
  const { gender } = getGender(state);
  const { locale } = state.intl;
  const disposition = getDirectMarketingDisposition(ctx.store.getState());
  const loyalty = getLoyaltyDetails(ctx.store.getState());
  const profileSizes = getProfileSizeFilters(
    ctx.store.getState().customer,
    gender
  );
  let experienceId;

  if (!ignoreExperienceIdTargeting) {
    try {
      experienceId = await getExperienceId({
        isLoggedIn,
        disposition,
        dmg: ctx.query?.dmg,
        gender: ctx.query?.gender,
        dispatch: ctx.store.dispatch,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('FAILED TO RETRIEVE DISPOSITION!!!!');
    }
  }

  const pageOptions = {
    experienceId,
    gender,
    locale,
    membershipStatus,
    isLoggedIn,
    isPreview,
    loyalty,
    vipPlusPerksAvailable: membership?.vipPlusPerksAvailable,
    bottomSize: profileSizes?.size_bottom,
    topSize: profileSizes?.size_top,
    braSize: profileSizes?.size_bra,
  };

  const pageTargetingOptions = getBuilderPageTargeting(pageOptions);

  const builderContentParams = {
    modelName,
    urlPath,
    query,
    pageTargetingOptions,
  };

  const { builderJson, key } = await getBuilderJson(builderContentParams);
  let builderStateData;

  // this if statement is intended to only execute for SEO purposes
  const processBuilderComponentsSSR = isVerifiedBot(ctx);
  if (processBuilderComponentsSSR) {
    /**
     * Loads asynchronous builder components
     * @components (BuilderCollection)
     */
    builderStateData = await loadAsyncBuilderComponents({
      ctx,
      builderJson,
    });
  }

  /**
   * Preloads assets/containers
   */
  await ssrPreprocessBuilderJson(ctx, builderJson);

  return { builderJson, builderStateData, key };
};
