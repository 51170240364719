import { getNavContainer } from '~/techstyle-shared/react-navigation';
import { createSelector } from '~/techstyle-shared/redux-core';

import { getCategories } from './categoryNav';

export const getCategoryNav = createSelector(
  [
    (state, asPath, navContainerName) =>
      getNavContainer(state, navContainerName),
    (state, asPath) => asPath,
  ],
  (navContainer, asPath) => {
    const navItems = navContainer?.data?.navItems || [];

    return getCategories({
      navItems,
      asPath,
    });
  }
);
