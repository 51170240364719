import config from 'config';

function getFableticsCategoryNav(region, startPath) {
  switch (region) {
    case 'EU':
      switch (startPath) {
        case 'scrubs':
          return config.get('public.brand.navigation.categoryNavScrubs');
        case 'mens':
          return config.get('public.brand.navigation.categoryNavMensEU');
        default:
          return config.get('public.brand.navigation.categoryNavWomensEU');
      }
    default:
      switch (startPath) {
        case 'scrubs':
          return config.get('public.brand.navigation.categoryNavScrubs');
        case 'mens':
          return config.get('public.brand.navigation.categoryNavMens');
        default:
          return config.get('public.brand.navigation.categoryNavWomens');
      }
  }
}

export function getCategoryNavContainer(region, asPath) {
  if (!asPath) {
    return null;
  }

  const startPath = asPath.split('/')[1];
  switch (config.public.brand.name) {
    // TODO: MOVE THESE BRAND VARIABLES TO A CONSTANTS FILE, SAME WITH REGIONS
    case 'yitty':
      return config.get('public.brand.navigation.categoryNav');
    case 'fabletics':
      return getFableticsCategoryNav(region, startPath);
  }
  return '';
}
