import { loadAssets } from '~/techstyle-shared/react-assets';

export const ssrPreprocessBuilderJson = async (ctx, builderJson) => {
  // We'll preprocess the blocks added to the page for any custom component pre-processing
  // so return if there is nothing defined in the custom area
  if (!builderJson?.data?.blocks) {
    return;
  }

  // Get the assets from the builderJson
  const assets = processBlocks(builderJson.data.blocks);

  // Filter undefined and null values
  const filteredAssets = assets?.filter(
    (element) => element !== undefined && element !== null
  );
  // Filter duplicates
  const uniqueArray = Array.from(new Set(filteredAssets));

  if (uniqueArray.length > 0) {
    await ctx.store.dispatch(loadAssets(uniqueArray));
  }
};

const processBlocks = (blocks) => {
  let assetsToLoad = [];
  for (const block of blocks) {
    const blockType = block?.component?.name;
    switch (blockType) {
      case '_Asset_':
      case '_Hero_':
      case '_MarketingCards_':
      case '_BubbleImageWithLabel_':
      case '_AssetOverlay_':
        assetsToLoad = [
          ...assetsToLoad,
          block.component?.options?.container,
          block.component?.options?.mobileContainer,
        ];
        break;
      case '_ConstructorGrid_':
        assetsToLoad = [
          ...assetsToLoad,
          block.component?.options?.assetContainer?.gridAssetContainer,
          block.component?.options?.assetContainer?.gridAssetContainerMobile,
          block.component?.options?.gridStyleGuide?.assetContainers?.desktop,
          block.component?.options?.gridStyleGuide?.assetContainers?.mobile,
          block.component?.options?.gridStyleGuide?.assetContainers
            ?.marketingCard,
        ];
        break;
      case '_Collection_':
        assetsToLoad = [
          ...assetsToLoad,
          block.component?.options?.gridAssetContainer,
          block.component?.options?.gridAssetContainerMobile,
          block.component?.options?.gridStyleGuide?.assetContainers?.desktop,
          block.component?.options?.gridStyleGuide?.assetContainers?.mobile,
          block.component?.options?.gridStyleGuide?.assetContainers
            ?.marketingCard,
        ];
        break;
      case '_GridList_':
        Array.prototype.forEach.call(
          block.component?.options?.collections,
          ({ gridAssetContainer, gridAssetContainerMobile }) => {
            assetsToLoad = [
              ...assetsToLoad,
              gridAssetContainer,
              gridAssetContainerMobile,
            ];
          }
        );
        break;
      case '_Tabs_':
        // TODO: this line is triggering linter issues regarding declaration of an array inside case block, maybe the array should be declared on a higher scope
        // eslint-disable-next-line no-case-declarations
        const tabsAssets = [];
        Array.prototype.forEach.call(
          block.component?.options?.tabs,
          ({ label }) => {
            Array.prototype.forEach.call(label, (block) => {
              const blockAssets = processBlocks([block]);
              tabsAssets.push(...blockAssets);
            });
          }
        );
        assetsToLoad = [...assetsToLoad, ...tabsAssets];
        break;
    }
  }

  return assetsToLoad;
};
